import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { getSalesByStaffReport } from "../../../store/actionCreators/Reports/StaffReportsAction";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
// import SBSPagination from "./SalesByStaffPagination";
// import { CSVLink } from "react-csv";
import { fetchEmployees } from "../../../store/actionCreators/Employees/EmployeeAction";
import Async from "react-select/async";
import axios from "axios";
import Loader from "../../Loader/LoaderTable";
import ScrollButton from "../../scrolltotop/ScrollButton";
import { toast } from "react-toastify";



const SalesByStaffReport = (props) => {
  const dispatch = useDispatch();
  const sbsReport = useSelector((state) => state.sales_by_staff);
  const sbsdReport = useSelector((state) => state.sales_by_staff_daily);

  const branch = useSelector((state) => state.branch);
  const employees = useSelector((state) => state.employees);
  const [page, setPage] = useState(1);
  const [showReportClicked, setShowReportClicked] = useState(false);

  const [isDataFetched, setisDataFetched] = useState(false);
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });
  const [empArr, setEmpArr] = useState({
    label: "All",
    value: "All",
  });
  const [query, setQuery] = useState({
    employee_id: "All",
    branch_id: "All",
    status: "All",
    // start: "2021-01-19",
    // end: "2022-01-02",

    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });
  const [empSelected, setEmpSelected] = useState({
    label: "All",
    value: "All",
  });
  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  const [durationSelected, setDurationSelected] = useState({
    label: "All",
    value: "All",
  });

  const [isDaily, setIsDaily] = useState(false);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    setProductData([]);
    dispatch(fetchEmployees(1, "All", "All", ""));
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [branch, employees]);

  useEffect(() => {
    if (sbsReport?.data?.status !== "success") {
      setisDataFetched(false);
    }
    if (
      sbsReport.data &&
      sbsReport?.data?.status === "success" &&
      showReportClicked
    ) {
      setisDataFetched(true);
      setIsDaily(false);
      const productDataClone = sbsReport?.data?.data.map((e) => {
        return {
          ...e,
          full_name: e.emplyees?.full_name,
          Sales:
            Number.parseFloat(
              Math.round(e.Sales * 100) / 100
            ).toLocaleString() || 0,
          Total_Tax:
            Number.parseFloat(
              Math.round(e.Total_Tax * 100) / 100
            ).toLocaleString() || 0,
          Cash_Sales:
            Number.parseFloat(
              Math.round(e.Cash_Sales * 100) / 100
            ).toLocaleString() || 0,
          card_sales:
            Number.parseFloat(
              Math.round(e.card_sales * 100) / 100
            ).toLocaleString() || 0,
          delivery_sales:
            Number.parseFloat(
              Math.round(e.delivery_sales * 100) / 100
            ).toLocaleString() || 0,
          // paid_price: e.paid_price.toLocaleString(),
          paid_price: Number.parseFloat(e.paid_price).toLocaleString(),
        };
      });
      productDataClone.push(total);

      setProductData(productDataClone);
      setPage(1);
    } else if (sbsReport?.data?.status === "failure") {
      setisDataFetched(true);
      setProductData([]);
    }
  }, [sbsReport, sbsReport?.data]);

  // useEffect(() => {
  //   if (
  //     sbsdReport?.data &&
  //     sbsdReport.data?.status === "success" &&
  //     durationSelected.value !== "All" &&
  //     showReportClicked
  //   ) {
  //     setIsDaily(true);
  //     setProductData(
  //       sbsdReport.data?.data.map((e) => ({
  //         ...e,
  //         full_name: e.emplyees.full_name,
  //         Sales: Math.round(e.Sales * 100) / 100 || 0,
  //         paid_price: Number.parseFloat(e.paid_price).toLocaleString(),
  //       }))
  //     );
  //     productData.push(total);
  //     setPage(1);
  //   }
  // }, [sbsdReport]);

  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_emp?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.full_name}`,
          value: e.employee_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const queryClone = { ...query };
  queryClone.branch_id = branchSelected.value;

  queryClone.employee_id = empSelected.value;

  const exportSBSCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_staff_reports`,
        queryClone
      );
      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      } else {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data?.data;
    } catch (error) {}
  };

  const setDefaultData = () => {
    if (branch.data && branch.data?.data) {
      const branchArrClone = branch.data?.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }

    if (employees.data && employees.data?.data) {
      const empArrClone = employees.data?.data.map((b) => ({
        label: b.full_name,
        value: b.employee_id,
      }));
      empArrClone.unshift({ label: "All", value: "All" });
      setEmpArr(empArrClone);
    }
  };

  const onShowReport = () => {
    setProductData([]);
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    queryClone.employee_id = empSelected.value;
    // if (durationSelected.value == "All")
    dispatch(getSalesByStaffReport(queryClone));
    // else dispatch(getSalesByStaffDaily(queryClone));
    setShowReportClicked(true);
    // exportSBSCSV();
  };

  const columnsDaily = [
    {
      dataField: "date",
      text: "Day",
      sort: true,
    },
    {
      dataField: "full_name",
      text: "Staff",
      sort: true,
    },
    {
      dataField: "order_count",
      text: "Order Count",
      sort: true,
    },
    {
      dataField: "paid_price",
      text: "Sales Total",
      sort: true,
    },
  ];

  const searchBoxes = {
    Cash_Sales_s: "Cash_Sales_s",

    Discount_s: "Discount_s",
    Order_Count_s: "Order_Count_s",
    Total_Tax_s: "Total_Tax_s",
    card_sales_s: "card_sales_s",

    action: "total",
  };

  const columns = [
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "Username",
      text: "Employee Name",
      sort: true,
    },
    {
      dataField: "Order_Count",
      text: "Order Count",
      sort: true,
    },
    {
      dataField: "Sales",
      text: "Total Sales",
      sort: true,
    },
    {
      dataField: "Cash_Sales",
      text: "Cash Sales",
      sort: true,
    },
    {
      dataField: "Average_Ticket_Size",
      text: "Average Ticket Size",
      sort: true,
    },

    {
      dataField: "card_sales",
      text: "Card Sales",
      sort: true,
    },
    {
      dataField: "wallet_sales",
      text: "Wallet Sales",
      sort: true,
    },
    {
      dataField: "delivery_sales",
      text: "Delivery Sales",
      sort: true,
    },
    {
      dataField: "membership_discount",
      text: "Membership Discount",
      sort: true,
    },
    {
      dataField: "coupon_discount",
      text: "Coupon Discount",
      sort: true,
    },
    {
      dataField: "Discount",
      text: "Total Discount",
      sort: true,
    },

    {
      dataField: "Total_Tax",
      text: "Total Tax",
      sort: true,
    },

    {
      dataField: "delivery_charges",
      text: "Delivery Partner charges",
      sort: true,
    },
   
    {
      dataField: "Average_Time_per_order",
      text: "Average Time per order",
      sort: true,
    },
  ];

  const total = {
    // not_include: true,
    branch_name: <strong>Total</strong>,
    Cash_Sales: (
      <strong>
        {sbsReport.data
          ? Number.parseFloat(sbsReport.data.Cash_Sales_s).toLocaleString()
          : ""}
      </strong>
    ),
    Order_Count: (
      <strong>
        {" "}
        {sbsReport.data
          ? Math.round(sbsReport.data.Order_Count_s * 100) / 100
          : ""}
      </strong>
    ),
    Discount: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.Discount_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    card_sales: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.card_sales_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    Total_Tax: (
      <strong>
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.Total_Tax_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    delivery_sales: (
      <strong>
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.total_delivery_sales_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    delivery_charges: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.delivery_charges_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    wallet_sales: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.wallet_sales_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    Sales: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.total_sales * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
  };

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: productData.length,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const orderStatusOptions = [
    { label: "All", value: "All" },
    { label: "Preparing", value: "Preparing" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  return (
    // <Container
    //   fluid
    //   className={props.sideToggle === true ? "closeDash" : "openDash"}
    // style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    // >
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">SALES BY STAFF</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Order status:</td>
                      <td className="">
                        <Select
                          options={orderStatusOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Employee:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Username:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        />
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>Duration:</td>
                      <td className="">
                        <Select
                          options={[
                            { label: "All", value: "All" },
                            { label: "Daily", value: "Daily" },
                          ]}
                          value={durationSelected}
                          onChange={(e) => setDurationSelected(e)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "csv", value: "csv" }]}
                value={{ label: "csv", value: "csv" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportSBSCSV();
                }}
              >
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="employee_id"
                    columns={isDaily ? columnsDaily : columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="employee_id"
                        columns={isDaily ? columnsDaily : columns}
                        data={productData}
                        search
                        // bootstrap4
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="employee_id"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  {/* {sbsReport?.data?.status === "failure" &&
                                    "Something went Wrong"} */}
                                  {!productData.length > 0 &&
                                  sbsReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {sbsReport?.data?.status === "failure" &&
                                    !sbsReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                </div>
                              </Col>
                            </Row>

                            {/* <Row>
                              <SBSPagination pageNum={Math.ceil(page)} />
                            </Row> */}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
    // </Container>
  );
};

export default SalesByStaffReport;

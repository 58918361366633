import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { getStaffOrderList } from "../../../store/actionCreators/Reports/StaffReportsAction";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SOLPagination from "../Staff/SOLPagination";
import { CSVLink } from "react-csv";
// import { fetchEmployees } from "../../store/actionCreators/Employees/EmployeeAction";
import Async from "react-select/async";
import axios from "axios";
import OrderModal from "../Staff/OrderModal";

import { NavLink } from "react-router-dom";
import Loader from "../../Loader/LoaderTable";
import ScrollButton from "../../scrolltotop/ScrollButton";
import { getCustomerSalesOrderList } from "../../../store/actionCreators/Reports/CustomerReportAction";
import { toast } from "react-toastify";

const CustomerSaleOrderList = (props) => {
  const dispatch = useDispatch();
  const solReport = useSelector((state) => state.customer_sales_order_list);
  const branch = useSelector((state) => state.branch);
  const employees = useSelector((state) => state.employees);
  const page = useSelector((state) => state.sol_page);

  const [branchArr, setBranchArr] = useState(["All"]);
  const [csv, setCSV] = useState({});
  const [isDataFetched, setisDataFetched] = useState(false);
  const [showReportClicked, setShowReportClicked] = useState(false);
  // const [empArr, setEmpArr] = useState(["All"]);
  const [RecordPage, setRecordPage] = useState(1);
  const [records, setRecords] = useState(100);
  const [orderModal, setOrderModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState("");
  const [paymentMethodSelected, setpaymentMethodSelected] = useState({
    label: "All",
    value: "All",
  });
  const [custSelected, setCustSelected] = useState({
    label: "Search Customer Number",
    value: "",
  });
  const [query, setQuery] = useState({
    customer_no: custSelected.value,
    branch_id: "All",
    status: "All",
    // start: "2021-01-19",
    // end: "2022-01-02",
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
    payment_method: "All",
  });

  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    dispatch(getCustomerSalesOrderList(query, RecordPage));
    // dispatch(fetchEmployees(1, "All", "All", ""));
  }, [RecordPage]);

  useEffect(() => {
    setDefaultData();
  }, [branch, employees]);

  useEffect(() => {
    if (solReport.data && solReport.data?.status === "success") {
      setisDataFetched(true);
      setProductData(
        solReport.data?.data.map((e) => ({
          ...e,
          full_name: e?.emplyees?.full_name,
          createdAt: e?.createdAt.toLocaleDateString,
          total_price:
            Number.parseFloat(
              Math.round(e.total_price * 100) / 100
            ).toLocaleString() || 0,
          membership_discount:
            Number.parseFloat(
              Math.round(e.membership_discount * 100) / 100
            ).toLocaleString() || 0,
          coupon_discount:
            Number.parseFloat(
              Math.round(e.coupon_discount * 100) / 100
            ).toLocaleString() || 0,
          total_discount:
            Number.parseFloat(
              Math.round(e.total_discount * 100) / 100
            ).toLocaleString() || 0,

          order_id: (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCurrentOrder(e?.order_id);
                setOrderModal(true);
              }}
            >
              <NavLink to="#">{e.order_id}</NavLink>
            </span>
          ),
          createdAt: new Date(e.createdAt).toLocaleString(),
          paid_price: Number.parseFloat(e.paid_price).toLocaleString(),
        }))
      );
      // setPage(solReport.data.data.length / 10);
    } else if (solReport?.data?.status === "failure") {
      setProductData([]);
      setisDataFetched(true);
    }
  }, [solReport]);

  const setDefaultData = () => {
    if (branch.data && branch.data?.data) {
      const branchArrClone = branch.data?.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }
  };

  const onShowReport = () => {
    setProductData([]);
    setRecordPage(1);
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    queryClone.customer_no = custSelected.value;

    if (queryClone?.customer_no?.length === 0) {
      // alert("PLEASE SELECT EMPLOYEE NAME");
      toast.error(`PLEASE SELECT CUSTOMER NUMBER`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      dispatch(getCustomerSalesOrderList(queryClone, RecordPage));
      setShowReportClicked(true);
    }
  };

  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_cust?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.mobile_no}`,
          value: e.mobile_no,
        }));
        // data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const queryClone = { ...query };
  queryClone.branch_id = branchSelected.value;
  queryClone.customer_no = custSelected.value;

  const exportCSOLCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_cust_sale_order_list`,
        queryClone
      );
      if (response) {
        window.open(response?.data?.url);
      }
      return response.data?.data;
    } catch (error) {}
  };

  const columns = [
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      sort: true,
    },
    {
      dataField: "order_id",
      text: "Order ID",
      sort: true,
    },

    {
      dataField: "total_items",
      text: "Number of Items",
      sort: true,
    },
    {
      dataField: "customer_no",
      text: "Customer Phone Number",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Purchased On",
      sort: true,
    },

    // for matching confirmation
    {
      dataField: "paid_price",
      text: "Net Sales",
      sort: true,
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      sort: true,
    },
    {
      dataField: "sub_total",
      text: "Subtotal",
      sort: true,
    },
    {
      dataField: "membership_discount",
      text: "Membersip Discount",
      sort: true,
    },
    {
      dataField: "coupon_discount",
      text: "Coupon Discount",
      sort: true,
    },

    {
      dataField: "total_discount",
      text: "Total Discount",
      sort: true,
    },

    {
      dataField: "tax",
      text: "Total Tax",
      sort: true,
    },
    {
      dataField: "total_price",
      text: "Total Amount",
      sort: true,
    },

    {
      dataField: "status",
      text: "Order Status",
      sort: true,
    },

    {
      dataField: "order_type",
      text: "Order Type",
      sort: true,
    },

    {
      dataField: "delivery_charges",
      text: "Delivery Partner charges",
      sort: true,
    },

    {
      dataField: "otp_status",
      text: "OTP Status",
      sort: true,
    },

    // {
    //   dataField: "status",
    //   text: "Order Delivered in",
    //   sort: true,
    // },
  ];

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: records,
    totalSize: solReport.data?.total_order_count, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const orderStatusOptions = [
    { label: "All", value: "All" },
    { label: "Preparing", value: "Preparing" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  // const paymentMethodOptions = [
  //   { label: "All", value: "All" },
  //   { label: "wallet", value: "wallet" },
  //   { label: "card+cash", value: "card+cash" },
  //   { label: "card", value: "card" },
  // ];

  const paymentMethodOptions = [
    { label: "All", value: "All" },
    { label: "wallet", value: "wallet" },
    { label: "card", value: "card" },
    { label: "cash", value: "cash" },
    { label: "account", value: "account" },
    { label: "wallet + card", value: "wallet + card" },
    { label: "wallet + cash,", value: "wallet + cash" },
    { label: "card + cash", value: "card + cash" },
  ];

  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">CUSTOMER SALES ORDER LIST</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Order status:</td>
                      <td className="">
                        <Select
                          options={orderStatusOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Username:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td>Customer No:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "", value: "" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={custSelected}
                          onChange={(e) => setCustSelected(e)}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Order Type:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td>Payment Method:</td>
                      <td className="">
                        <Select
                          options={paymentMethodOptions}
                          value={{
                            label: query.payment_method,
                            value: query.payment_method,
                          }}
                          onChange={(e) =>
                            setQuery({ ...query, payment_method: e.value })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked ? (
        <React.Fragment>
          <OrderModal
            show={!!orderModal}
            close={() => setOrderModal(false)}
            data={{}}
            order_id={currentOrder}
          />
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "csv", value: "csv" }]}
                value={{ label: "csv", value: "csv" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportCSOLCSV();
                }}
              >
                {/* <CSVLink
                  data={productData.map((p) => ({
                    Staff: p.full_name,
                    Order_ID: p.order_id,
                    Increment_ID: p.branch_name,
                    Sales_Total: p.paid_price,
                    Purchased_On: p.createdAt,
                    Status: p.status,
                  }))}
                  filename={"report.csv"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Export
                </CSVLink> */}
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="order_id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="order_id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="order_id"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {/* {!isDataFetched && <Loader />} */}
                                  {/* {solReport?.data?.status === "failure" &&
                                    "Something went Wrong"} */}
                                  {/* {productData?.length === 0 &&
                                    isDataFetched &&
                                    "No Data Found"} */}
                                  {!productData.length > 0 &&
                                  solReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {solReport?.data?.status === "failure" &&
                                    !solReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <SOLPagination
                                  pageNum={Math.ceil(
                                    solReport.data
                                      ? solReport.data.total_order_count /
                                          records
                                      : 1
                                  )}
                                  page={RecordPage}
                                  setRecordPage={setRecordPage}
                                />
                              </Col>
                              {/* <Col className="d-flex">
                                <div style={{ marginLeft: "auto" }}>
                                  <Select
                                    options={[
                                      { label: "100", value: "100" },
                                      { label: "500", value: "500" },
                                      { label: "1000", value: "1000" },
                                    ]}
                                    onChange={(e) => {
                                      setRecordPage(1);
                                      dispatch(
                                        getStaffOrderList(
                                          query,
                                          e.value,
                                          RecordPage
                                        )
                                      );
                                      setRecords(e.value);
                                    }}
                                    className="ml-auto"
                                    value={{ label: records, value: records }}
                                  />
                                </div>
                              </Col> */}
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default CustomerSaleOrderList;

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Modal,
  Form,
} from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import { Link, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import Unauthorized from "../../unauthorized";
import NoData from "../../NoData";
import { getAllBranchId } from "../../../store/actionCreators/Employees/EmployeeAction";
import { GetEmployeeLoginDetails } from "../../../store/actionCreators/Employees/EmployeeAction";
import { useCallback } from "react";
import CustomerTablePagination from "../../Customer/CustomerTablePagination";
import { setCustomerPagination } from "../../../store/actionCreators/Customers/CustomerAction";
// import ReportModal from "../../Employees/individualEmployeeWid/ReportModal";
import Select from "react-select";
// import { getCustomLogoutReport } from "./../../../store/actionCreators/Employees/EmployeeAction";
import { getCustomLogoutReport } from "../../../store/actionCreators/Reports/SingleEmployeeLogoutReportAction";
import StaffLogoutReportModal from "../StaffLogoutReportModal";

const StaffLogoutReportTable = ({ empSelected }) => {
  // console.log(empSelected, "hhh");
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [customReportModal, setCustomReportModal] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState({});
  const [showReportModal, setShowReportModal] = useState(false);
  const [customReportData, setCustomReportData] = useState({
    log_in: "",
    log_out: "",
    branch_id: "",
  });

  // const [pageNo, setPageNo] = useState(1);
  const page = useSelector((state) => state.emp_orders_page);

  const logoutEmployeeReportData = useSelector(
    (state) => state.employee_logout_Report
  );
  const singleCustomLogoutReport = useSelector(
    (state) => state.single_custom_logout_report
  );

  const employee = useSelector((state) => state.employee);

  const allBranchId = useSelector((state) => state.branches);

  const [branchObj, setBranchObj] = useState({});

  const [filters, setFilters] = useState({
    branch_id: empSelected.branch_id,
    employee_id: empSelected.employee_id,
    log_in: "",
    log_out: "",
  });

  useEffect(() => {
    const branchObjClone = {};
    if (allBranchId.data && allBranchId.data.data) {
      allBranchId.data.data.forEach((b) => {
        branchObjClone[b.branch_id] = b.branch_name;
      });
      setBranchObj(branchObjClone);
    }
  }, [allBranchId]);

  useEffect(() => {
    dispatch(getAllBranchId());
  }, [dispatch]);

  const onChangeForSearchFields = (field, value) => {
    const filters_clone = { ...filters };
    filters_clone[field] = value;
    setFilters(filters_clone);
  };

  useEffect(() => {
    // console.log(empSelected, "check");
    dispatch(GetEmployeeLoginDetails(page, empSelected));
  }, [page, empSelected]);

  const showFilteredReport = useCallback(
    (page) => {
      dispatch(GetEmployeeLoginDetails(page, filters));
      dispatch(setCustomerPagination(page));
    },

    [filters, page]
  );

  function rankFormatter(cell, row) {
    // console.log({ ...row }, "cheee");
    if (row.action === "search") return "";
    return (
      <div
        style={{
          textAlign: "start",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <Button
          onClick={() => {
            setShowReportModal(true);

            setCurrentEmployee({ ...row });
          }}
          className="btn btn-sm btn-warning"
        >
          Report
        </Button>
      </div>
    );
  }
  // console.log(filters.log_in, "checking");
  const branchIdDrop = () => {
    return allBranchId?.data?.data.map((item, index) => {
      return (
        <option key={index} value={item.branch_id}>
          {item.branch_name}
        </option>
      );
    });
  };

  const searchBoxes = {
    log_in_time: (
      <input
        type="date"
        id="log_in"
        placeholder="Login date"
        style={{ width: "7rem" }}
        value={filters.log_in}
        onChange={(e) => onChangeForSearchFields("log_in", e.target.value)}
      />
    ),
    log_out_time: (
      <input
        type="date"
        id="log_out"
        placeholder="Login date"
        style={{ width: "7rem" }}
        value={filters.log_out}
        onChange={(e) => onChangeForSearchFields("log_out", e.target.value)}
      />
    ),

    branch_name: (
      <select
        className="form-control form-select form-select-sm"
        value={filters.branch_id}
        onChange={(e) => {
          onChangeForSearchFields("branch_id", e.target.value);
        }}
      >
        <option value="">branch id's</option>
        {branchIdDrop()}
      </select>
    ),

    action: "search",
  };

  const columns = [
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    // {
    //   dataField: "full_name",
    //   text: "Employee Name",
    //   sort: true,
    // },
    {
      dataField: "employee_logout_details_id",
      text: "Employee Logout Details Id",
      sort: true,
    },
    {
      dataField: "log_in_time",
      text: "Log In",
      sort: true,
    },
    {
      dataField: "log_out_time",
      text: "Log Out",
      sort: true,
    },

    {
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "customer_type",
      order: "asc",
    },
  ];
  const pageOptions = {
    sizePerPage: 10,
    totalSize: logoutEmployeeReportData?.data
      ? logoutEmployeeReportData?.data?.data?.length
      : 0, // replace later with size(customers),
    custom: true,
  };

  const getAllRows = () => {
    if (!logoutEmployeeReportData?.data?.data) {
      return [searchBoxes];
    }
    const rows = [
      ...logoutEmployeeReportData?.data?.data.map((i) => ({
        ...i,
        full_name: employee?.data?.data ? employee?.data?.data?.full_name : "",
        branch_name: branchObj[i.branch_id],
        log_in_time: i.log_in ? new Date(i.log_in).toLocaleString() : "",
        log_out_time: i.log_out ? new Date(i.log_out).toLocaleString() : "",
        createdAt: new Date(i.createdAt).toLocaleString(),
      })),
    ];
    rows.unshift(searchBoxes);
    return rows;
  };

  const notFound = () => {
    if (logoutEmployeeReportData.data?.status === "failure") {
      return (
        <div>
          <h1>No Details Or Report Found</h1>
        </div>
      );
    }
  };

  // console.log(empSelected,"dataaa")

  const main = () => {
    if (logoutEmployeeReportData?.data) {
      // if (logoutEmployeeReportData?.data?.data) {
      return (
        <Container
          fluid
          className="my-2"
          //   className={props.sideToggle === true ? "closeDash" : "openDash"}
          //   style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <Row>
            <div className="page-content ">
              <form>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <Card.Body>
                        <Card.Title className="h4 mb-2 ">
                          Logout Report Details{" "}
                        </Card.Title>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="d-flex">
                              <Button
                                className="btn btn-warning mb-3 "
                                onClick={() => showFilteredReport(1, filters)}
                                style={{ marginRight: "1rem" }}
                              >
                                Show Filtered Report
                              </Button>
                              <Button
                                className="btn btn-warning mb-3 "
                                onClick={() => {
                                  setCustomReportModal(true);
                                }}
                                style={{ marginRight: "1rem" }}
                              >
                                Get Custom Report
                              </Button>
                            </div>
                          </Col>
                          <strong>Employee Name: {empSelected.label}</strong>
                        </Row>
                        <Modal
                          show={customReportModal}
                          closeButton
                          onHide={() => setCustomReportModal(false)}
                        >
                          <Modal.Header closeButton>
                            Get Custom Report
                          </Modal.Header>
                          <Modal.Body>
                            <Form
                              onSubmit={() => {
                                console.log("onsubmit called");
                                dispatch(
                                  getCustomLogoutReport({
                                    ...customReportData,
                                    employee_id: empSelected.employee_id,
                                  })
                                );
                                navigate(
                                  "/reports/staff/stafflogoutreport/report",
                                  {
                                    state: {
                                      report:
                                        singleCustomLogoutReport?.data?.data,
                                    },
                                  }
                                );
                              }}
                            >
                              <label>Log in: </label>
                              <Form.Control
                                type="date"
                                name="log_in"
                                required
                                onChange={(e) =>
                                  setCustomReportData({
                                    ...customReportData,
                                    log_in: e.target.value,
                                  })
                                }
                              />
                              <label>Log out: </label>
                              <Form.Control
                                type="date"
                                name="log_out"
                                required
                                onChange={(e) =>
                                  setCustomReportData({
                                    ...customReportData,
                                    log_out: e.target.value,
                                  })
                                }
                              />
                              <label>Branch: </label>
                              <Select
                                required
                                onChange={(e) =>
                                  setCustomReportData({
                                    ...customReportData,
                                    branch_id: e.value,
                                  })
                                }
                                options={
                                  allBranchId.data
                                    ? allBranchId.data.data
                                      ? allBranchId.data.data.map((b) => ({
                                          label: b.branch_name,
                                          value: b.branch_id,
                                        }))
                                      : []
                                    : []
                                }
                              />
                              <Button className="mt-2" type="submit">
                                Get Report
                              </Button>
                            </Form>
                          </Modal.Body>
                        </Modal>

                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="employee_logout_details_id"
                          columns={columns}
                          data={getAllRows()}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="employee_logout_details_id"
                              columns={columns}
                              search
                              data={getAllRows()}
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="employee_logout_details_id"
                                          responsive
                                          bordered={true}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                        {!logoutEmployeeReportData?.data
                                          ?.data && (
                                          <div className="m-2">
                                            NO DATA FOUND
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl={12}>
                                      <CustomerTablePagination
                                        pageNum={Math.ceil(
                                          (logoutEmployeeReportData.data
                                            ? logoutEmployeeReportData?.data
                                                ?.total_count
                                            : 0) / 10
                                        )}
                                        onPageChange={showFilteredReport}
                                      />
                                    </Col>
                                  </Row>
                                  {/* <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                          <div className="d-inline">
                                            <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                          <div className="text-md-right ms-auto">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                              className="table-pagination"
                                            />
                                          </div>
                                        </Col>
                                      </Row> */}
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </form>
            </div>
          </Row>
          <StaffLogoutReportModal
            show={showReportModal}
            close={() => setShowReportModal(false)}
            data={currentEmployee}
          />
        </Container>
      );
      // }
    }

    return <Unauthorized />;
  };
  return <React.Fragment>{main()}</React.Fragment>;
};

export default StaffLogoutReportTable;

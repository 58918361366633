import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import CBGPagination from "./CBGPagination";
import Async from "react-select/async";
import axios from "axios";
import { getCustomerSalesSummaryReport } from "./../../../store/actionCreators/Reports/CustomerReportAction";
import ScrollButton from "../../scrolltotop/ScrollButton";
import Loader from "../../Loader/LoaderTable";
import { toast } from "react-toastify";

const CustomerSalesSummary = (props) => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.cbg_page);
  const branch = useSelector((state) => state.branch);
  const cssReport = useSelector((state) => state.customer_sales_summary_report);
  const [showReportClicked, setShowReportClicked] = useState(false);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });
  const [paymentTypeSelected, setPaymentTypeSelected] = useState({
    label: "All",
    value: "All",
  });

  const [custSelected, setCustSelected] = useState({
    label: "Search Customer Number",
    value: "",
  });

  const [query, setQuery] = useState({
    customer_no: custSelected?.value,
    branch_id: "All",
    payment_method: "All",
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });

  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    dispatch(getCustomerSalesSummaryReport(query));
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [branch]);

  const total = {
    // not_include: true,
    customer_no: <strong>Total</strong>,
    discount: (
      <strong>
        {cssReport.data
          ? Number.parseFloat(cssReport.data.discount).toLocaleString()
          : ""}
      </strong>
    ),
    membership_discount: (
      <strong>
        {" "}
        {cssReport.data
          ? Math.round(cssReport.data.membership_discount * 100) / 100
          : ""}
      </strong>
    ),
    Paid_Price: (
      <strong>
        {" "}
        {cssReport.data
          ? Number.parseFloat(
              Math.round(cssReport.data.total_price * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    tax: (
      <strong>
        {" "}
        {cssReport.data
          ? Number.parseFloat(
              Math.round(cssReport.data.tax * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
  };

  useEffect(() => {
    if (cssReport?.data?.status !== "success") {
      setisDataFetched(false);
    }
    if (cssReport.data && cssReport.data.status === "success") {
      setisDataFetched(true);
      const productDataClone = cssReport.data.data.map((e) => {
        return {
          ...e,
          discount:
            Number.parseFloat(
              Math.round(e.discount * 100) / 100
            ).toLocaleString() || 0,
          tax:
            Number.parseFloat(Math.round(e.tax * 100) / 100).toLocaleString() ||
            0,
          membership_discount:
            Number.parseFloat(
              Math.round(e.membership_discount * 100) / 100
            ).toLocaleString() || 0,
          Paid_Price:
            Number.parseFloat(
              Math.round(e.Paid_Price * 100) / 100
            ).toLocaleString() || 0,
          total_discount:
            Number.parseFloat(
              Math.round(e.total_discount * 100) / 100
            ).toLocaleString() || 0,
        };
      });
      productDataClone.push(total);
      setProductData(productDataClone);
    } else if (cssReport?.data?.status === "failure") {
      setisDataFetched(true);
      setProductData([]);
    }
  }, [cssReport]);

  const setDefaultData = () => {
    if (branch.data && branch.data.data) {
      const branchArrClone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }
  };

  const onShowReport = () => {
    setProductData([]);
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    queryClone.customer_no = custSelected.value;
    queryClone.payment_method = paymentTypeSelected.value;
    if (queryClone?.customer_no?.length === 0) {
      // alert("PLEASE SELECT EMPLOYEE NAME");
      toast.error(`PLEASE SELECT CUSTOMER NUMBER`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      dispatch(getCustomerSalesSummaryReport(queryClone));
      setShowReportClicked(true);
    }
  };

  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_cust?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.mobile_no}`,
          value: e.mobile_no,
        }));
        console.log(data, "check");
        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const queryClone = { ...query };
  queryClone.branch_id = branchSelected.value;
  queryClone.customer_no = custSelected.value;
  queryClone.payment_method = paymentTypeSelected.value;

  const exportCSSCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_cust_sale_sum`,
        queryClone
      );
      if (response) {
        window.open(response?.data?.url);
      }
      return response.data.data;
    } catch (error) {}
  };

  const columns = [
    {
      dataField: "customer_no",
      text: "Customer Phone Number",
      sort: true,
    },
    {
      dataField: "Paid_Price",
      text: "Total Spending",
      sort: true,
    },

    {
      dataField: "discount",
      text: "Discount",
      sort: true,
    },
    {
      dataField: "membership_discount",
      text: "Membership Discount",
      sort: true,
    },
    {
      dataField: "total_discount",
      text: "Total Discount",
      sort: true,
    },
    {
      dataField: "tax",
      text: "Tax",
      sort: true,
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      sort: true,
    },
  ];

  const pageOptions = {
    sizePerPage: cssReport.data?.total_count,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // const { SearchBar } = Search;

  const paymentTypeOptions = [
    { label: "All", value: "All" },
    { label: "wallet", value: "wallet" },
    { label: "card", value: "card" },
    { label: "cash", value: "cash" },
    { label: "account", value: "account" },
    { label: "wallet + card", value: "wallet + card" },
    { label: "wallet + cash,", value: "wallet + cash" },
    { label: "card + cash", value: "card + cash" },
  ];

  // const paymentTypeOptions = [
  //   { label: "All", value: "All" },
  //   { label: "cash", value: "cash" },
  //   { label: "wallet", value: "wallet" },
  //   { label: "card+cash", value: "card+cash" },
  //   { label: "wallet+cash", value: "wallet+cash" },
  //   { label: "wallet+card", value: "wallet+card" },
  // ];
  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">CUSTOMER SALES SUMMARY</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Method:</td>
                      <td className="">
                        <Select
                          options={paymentTypeOptions}
                          value={paymentTypeSelected}
                          onChange={(e) => setPaymentTypeSelected(e)}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Customer Number:</td>
                      <td className="">
                        <input
                          type="number"
                          value={customerNumber}
                          onChange={(e) => setCustomerNumber(e)}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td>Customer No:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "", value: "" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={custSelected}
                          onChange={(e) => setCustSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>

                    {/* <tr>
                      <td>Customer Name:</td>
                      <td className="">
                        <input
                          type="text"
                          value={customerName}
                          onChange={(e) => setCustomerName(e)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "csv", value: "csv" }]}
                value={{ label: "csv", value: "csv" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportCSSCSV();
                }}
              >
                {/* <CSVLink
              data={productData.map((p) => {
                return {
                  Order_Count: p.order_count,
                  Branch: p.branch_name,
                  Customer_Type: p.customer_type,
                };
              })}
              filename={"report.csv"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Export
            </CSVLink> */}
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="employee_id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="employee_id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-flex">
                                  {/* <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div> */}

                                  <Card
                                    style={{
                                      background: "transparent",
                                      width: "100%",
                                      color: "#8d8d8d",
                                      padding: "10px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Card.Body>
                                      <Row>
                                        <Col>
                                          {" "}
                                          <strong> Customer Name : </strong>
                                          <span className="text-capitalize">
                                            {
                                              cssReport?.data?.custfndone
                                                ?.first_name
                                            }
                                          </span>
                                        </Col>
                                        <Col>
                                          {" "}
                                          <strong>
                                            Customer Lifetime Spend :
                                          </strong>
                                          {
                                            cssReport?.data.custfndone
                                              ?.total_spend
                                          }{" "}
                                        </Col>
                                        <Col>
                                          {" "}
                                          <strong> Customer Category : </strong>
                                          {
                                            cssReport?.data.custfndone
                                              ?.customer_type
                                          }{" "}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {" "}
                                          <strong>
                                            {" "}
                                            Customer Upgrade Category:
                                          </strong>{" "}
                                          {
                                            cssReport?.data.custfndone
                                              ?.memb_upg_categ
                                          }{" "}
                                        </Col>
                                        <Col>
                                          {" "}
                                          <strong> Wallet Balance: </strong>
                                          {
                                            cssReport?.data.custfndone
                                              ?.wallet_balance
                                          }{" "}
                                        </Col>
                                        <Col>
                                          <strong> Retaining Amt. : </strong>
                                          {
                                            cssReport?.data.custfndone
                                              ?.memb_reduce_amount
                                          }{" "}
                                        </Col>
                                      </Row>
                                      <Row className=" ">
                                        <Col>
                                          {" "}
                                          <strong> Upg. Amt. : </strong>
                                          {
                                            cssReport?.data.custfndone
                                              ?.memb_upg_amount
                                          }{" "}
                                        </Col>
                                        <Col>
                                          <strong> Days Left: </strong>
                                          {
                                            cssReport?.data.custfndone
                                              ?.memb_days_left
                                          }{" "}
                                        </Col>
                                        <Col>
                                          {" "}
                                          <strong> Customer No: </strong>
                                          {
                                            cssReport?.data.custfndone
                                              ?.mobile_no
                                          }{" "}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="employee_id"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  {!productData.length > 0 &&
                                  cssReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {cssReport?.data?.status === "failure" &&
                                    !cssReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                </div>
                              </Col>
                            </Row>

                            {/* <Row>
                          <CBGPagination
                            pageNum={Math.ceil(
                              cssReport.data ? cssReport.data.total_count : 1
                            )}
                          />
                        </Row> */}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
    // </Container>
  );
};

export default CustomerSalesSummary;

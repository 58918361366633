import axios from "axios";
import { toast } from "react-toastify";

export const fetchSingleEmployee = (emp_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_employee?employee=${emp_id}`
      )
      .then((employee) => {
        dispatch({
          type: "GET_SINGLE_EMPLOYEE",
          employee,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const fetchEmployees = (page, franchise_id, branch_id, search, records=10) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_all_employees/${page}?franchise=${franchise_id}&branch=${branch_id}&search=${search}&per_page=${records}`
      )
      .then((employees) => {
        dispatch({
          type: "GET_EMPLOYEES",
          employees,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const EmployeeSalesAnalytics = (emp_id, filter, year, month) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/employee_sales_analytics?employee=${emp_id}&filter_by=${filter}&year=${year}&month=${month}`
      )
      .then((employee_sales_analytics) => {
        dispatch({
          type: "EMPLOYEE_SALES_ANALYTICS",
          employee_sales_analytics,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const editEmployeeDetails = (Emp, emp_id) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}api/v1/admin/employee/update_single_employee?employee_id=${emp_id}`,
      Emp
    )
    .then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        toast.success(`successFully Updated Employee Details`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 4000);
      } else {
        toast.error(`failure`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      return res;
    })
    .catch((err) => {
      console.log("error", err);
    });
};

export const addEmployees = (OB) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}api/v1/admin/employee/add_new_employee`,
      {
        full_name: OB.full_name,
        mobile_no: OB.mobile_no,
        email: OB.email,
        password: OB.password,
        profile_pic:
          "https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_dark_1x_r2.png",
        date_of_birth: OB.date_of_birth,
        address: OB.address,
        branch: OB.branch,
        branch_id: OB.branch_id,
        employee_role: OB.employee_role,
        employee_role_id: OB.employee_role_id,
        gender: OB.gender,
      }
    )
    .then((res) => {
      if (res?.data?.status === "success") {
        toast.success(`successFully Added Employee`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 4000);
      }
      if (res?.data?.status === "failure") {
        toast.error(`${res?.data?.msg}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
    .catch((err) => {
      console.log("error", err);
    });
};

// Get Employee Login Details
export const GetEmployeeLoginDetails = (page, OB) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_employee_login_details/${page}`,
        {
          employee_id: OB?.employee_id,
          branch_id: OB?.branch_id,
          log_in: OB.log_in,
          log_out: OB.log_out,
        }
      )
      .then((res) => {
        dispatch({
          type: "GET_LOGOUT_EMPLOYEE_REPORT",
          employee_logout_Report: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getCustomLogoutReport = (OB) => {
  console.log(OB, "object bhaiya");
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_custom_single_employee_logout_report`,
        {
          employee_id: OB?.employee_id,
          branch_id: OB?.branch_id,
          log_in: OB.log_in,
          log_out: OB.log_out,
        }
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "GET_SINGLE_EMPLOYEE_LOGOUT_REPORT",
          logout_report_data: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getLogoutReport = (OB) => {
  // console.log(OB, "object bhaiya");
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_single_employee_logout_report`,
        {
          employee_id: OB?.employee_id,
          branch_id: OB?.branch_id,
          log_in: OB.log_in,
          log_out: OB.log_out,
        }
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_EMPLOYEE_LOGOUT_REPORT",
          logout_report_data: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getEmployeePagination = () => {
  return (dispatch, getSate) => {
    dispatch({
      type: "GET_EMPLOYEE_PAGE",
    });
  };
};
export const setEmployeePagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_EMPLOYEE_PAGE",
      page,
    });
  };
};
export const getEmployeeRecords = () => {
  return (dispatch, getSate) => {
    dispatch({
      type: "GET_EMPLOYEE_RECORDS",
    });
  };
};
export const setEmployeeRecords = (records) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_EMPLOYEE_RECORDS",
      records,
    });
  };
};

export const fetchCareer = (page, search) => {
  return (dispatch, getState) => {
    console.log("career");
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }api/v1/admin/employee/get_careers/${page}${
          search ? `?search=${search}` : ""
        }`
      )
      .then((res) => {
        dispatch({
          type: "GET_CAREER",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setCareerPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_CAREER_PAGE",
      page,
    });
  };
};
export const EmployeeOrdersTaken = (page, emp_id, search) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_employee_orders/${page}?employee=${emp_id}&search=${search}`
      )
      .then((employee_orders_taken) => {
        dispatch({
          type: "EMPLOYEE_ORDERS_TAKEN",
          employee_orders_taken,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getOrderPagination = () => {
  return (dispatch, getSate) => {
    dispatch({
      type: "GET_ORDER_PAGE",
    });
  };
};
export const setOrderPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_ORDER_PAGE",
      page,
    });
  };
};

export const getEmployeeRoles = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/get_employee_roles`
      )
      .then((roles) => {
        dispatch({
          type: "GET_ROLES",
          roles,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getAllBranchId = () => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/fetch_all_branches`
      )
      .then((branches) => {
        console.log(branches);
        dispatch({
          type: "GET_ALL_BRANCHES",
          branches,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const resetEmployee = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_EMPLOYEES",
    });
    dispatch({
      type: "ET_SINGLE_EMPLOYEE",
    });
    dispatch({
      type: "SET_EMPLOYEE_SALES_ANALYTICS",
    });
    dispatch({
      type: "SET_EMPLOYEE_PAGE",
      page: 1,
    });
    dispatch({
      type: "SET_EMPLOYEE_ORDERS_TAKEN",
    });
    dispatch({
      type: "SET_ROLES",
    });
  };
};

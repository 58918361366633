import FranchiseReducer from "./Franchise/FranchiseReducer";
import { BranchReducer, SingleBranchReducer } from "./Branch/BranchReducer";
import { combineReducers } from "redux";
import {
  ProductsReducer,
  AddonsReducer,
  CategoryReducer,
  get_category_branchesReducer,
  get_product_branchReducer,
  UpdateReducer,
  AddProductToCat,
  GET_CURRENT_CAT,
  GET_SINGLE__ADDON,
  GET_CURRENT_BRANCH,
  DELETE__PRODUCT,
  UPDATE__PRODUCT,
  ITEMS__AVAILABLE,
  UPDATE_ADDON__PRODUCT,
  DELETE_SINGLE__ADDON,
  DELETE__CATEGORY,
  DELETE_PRODUCT,
  DELETE_ADDON_OPTION,
  getAddonProducts,
  DELETE_ADDON_PRODUCTS,
  AddProductToAddon,
  getProductAddons,
  QRCodesReducer,
  SingleQRCodeReducer,
} from "./Catalog/getProductsRed";
import SidebarReducer from "./sidebarReducer";
import {
  dashboard_card,
  dashboard_filters,
  dashboard_ml_ordered_products,
  dashboard_revenue,
  dashboard_sales_pie,
} from "./dashboardReducer";
import {
  CustomerAnalyticsGraphReducer,
  CustomerAnalyticsPieReducer,
  CustomerAvgPurchaseReducer,
  CustomerDashboardReducer,
  CustomerMostOrderGraphReducer,
  CustomerOrderHistoryReducer,
  CustomerPaginationReducer,
  fetchCustomersReducer,
  MostOrderPaginationReducer,
  OrderAnalyticsGraphReducer,
  singleCustomerReducer,
} from "./Customer/customerReducer";
import { CustomerWalletBalanceReducer } from "./Employee/CustomerReportReducer";
import {
  CareerPaginationReducer,
  EmployeeOrdersTakenReducer,
  EmployeePaginationReducer,
  EmployeePaginationRecordsReducer,
  EmployeeSalesAnalyticsReducer,
  fetchCareerReducer,
  fetchEmployeesReducer,
  fetchSingleEmployeeReducer,
  getAllBranchesReducer,
  getSingleEmployeeReport,
  OrdersPaginationReducer,
  RolesReducer,
} from "./Employee/EmployeeReducer";
import authReducer from "./loginReducer";
import { get_admin_rolesReducer } from "./User/UserReducer";
import { get_all_adminsReducer } from "./Admins/AdminsReducer";
import { single_adminReducer } from "./Admins/singleAdminReducer";
import {
  AllOrdersPaginationReducer,
  ordersReducer,
  FilteredCustomersReducer,
  orderReducer,
} from "./Orders/ordersReducer";
import {
  couponsReducer,
  CouponsPaginationReducer,
  couponReducer,
  promosReducer,
  singlePromoReducer,
  singleGiftReducer,
  giftCardsReducer,
  couponGroupReducer,
  getSingleProductReducer,
  allCouponsReducer,
  getSingleCouponReducer,
  GiftCardPaginationReducer,
  promoCodePaginationReducer,
} from "./Coupons/CouponsReducer";
import { CustomerRolesReducer } from "./Customer/customerRolesReducer";
import SingleFranchiseReducer from "./Franchise/SingleFranchiseReducer";
import {
  groupReducer,
  groupsReducer,
  GroupCustomersPaginationReducer,
  SearchCustomerReducer,
} from "./Groups/groupsReducer";
import { FilteredCustomersListReducer } from "./Customer/customerReducer";
import { logoutEmployeeReportReducer } from "./Employee/EmployeeReducer";
import {
  fetchSalesByStaff,
  SalesByStaffPagination,
  fetchSalesByStaffDaily,
  SalesByStaffDailyPagination,
  fetchStaffOrderList,
  StaffOrderListPagination,
  fetchDailyInvestorReport,
  fetchCustomInvestorReport,
  fetchSalesByBranch,
  fetchSalesByBranchDaily,
} from "./Employee/ReportsReducer";
import {
  fetchOrderListLocation,
  OrderListLocationPagination,
  fetchItemWiseSalesSummary,
} from "./Employee/OrderReportsReducer";
import {
  SalesByLocationPagination,
  fetchSalesByLocation,
  SalesByLocationDailyPagination,
  fetchSalesByLocationDaily,
} from "./Employee/LocationReportsReducer";
import {
  SalesByPaymentMethodReducer,
  SBPMPagination,
  SalesByPaymentMethodDailyReducer,
  SBPMDPagination,
  walletCreditHistoryReducer,
} from "./Employee/PaymentReportsReducer";

import {
  CLBGPagination,
  CBGPagination,
  CustomerByGroupReportReducer,
  CustomerSalesSummaryReducer,
  CustomerSalesOrderListReducer,
  PCOLPagination,
  PerCustomerOrderList,
  PerCustomerAnalysisReport,
  CustomerListByGroupReportReducer,
  CustomerGroupChangeLogsReducer,
} from "./Employee/CustomerReportReducer";

import {
  fetchSingleEmployeeLogoutReport,
  fetchCustomLogoutReport,
} from "./Employee/ReportsReducer";
// investor section
import {
  addBranchInvestor,
  AllCompanyReducer,
  AllInvestorReducers,
  deleteBranchInvestorReducer,
  deleteInvestorReducer,
} from "./Investor/InvestorReducer";
import {
  EmployeeListPaginationReducer,
  ItemsListPaginationReducer,
  PurchaseOrderListPaginationReducer,
  StoreListPaginationReducer,
  VendorListPaginationReducer,
  allCafeRequestListReducer,
  branchesInventoryReducer,
  cafeEmployeeListPaginationReducer,
  cafeRequestPaginationReducer,
  cafeStockPaginationReducer,
  cafeStockReducer,
  inventoryAllItemsListReducer,
  inventoryAllPurchaseOrderReducer,
  inventoryAllStoreReducer,
  inventoryAllStoreReqReceiver,
  inventoryAllStoreReqSenderListReducer,
  inventoryAllVendorReducer,
  inventoryCafeEmployeeList,
  inventoryCountCafeRequest,
  inventoryCountPurchaseOrderReducer,
  inventoryCountStoreReqReceiverReducer,
  inventoryCountStoreReqSenderReducer,
  inventoryEmployeesReducer,
  inventoryItemsUnitReducer,
  inventoryStoreReducer,
  singleCafeEmployeeReducer,
  singleCafeRequestReducer,
  singleEmployeeReducer,
  singleItemReducer,
  singlePurchaseOrderReducer,
  singleStoreReducer,
  singleStoreSenderReqReducer,
  singleVendorReducer,
  soldProductReportReducer,
  storeReceiveReqListPaginationReducer,
  storeSenderReqListPaginationReducer,
  storeStockPaginationReducer,
  storeStockReducer,
} from "./Inventory/InventoryReducer";
import {
  allNotificationListReducer,
  getNotificationBranchesReducer,
  getSingleNotificationReducer,
  notificationPaginationReducer,
} from "./Notification/NotificationReducer";
import {
  availableCreditReducer,
  creditAvailableListPagination,
  creditUsedListPagination,
  singleCreditReducer,
  usedCreditReducer,
} from "./Credits/CreditReducer";
import { getAllOutletsReducer } from "./Zomato/ZomatoReducers";

// import { exportsbsCSV } from "./Employee/ReportsReducer";

const rootReducer = combineReducers({
  login: authReducer,
  franchise: FranchiseReducer,
  single_franchise: SingleFranchiseReducer,
  branch: BranchReducer,
  products: ProductsReducer,
  addons: AddonsReducer,
  categories: CategoryReducer,
  toggle: SidebarReducer,
  role: RolesReducer,
  admin_role: get_admin_rolesReducer,
  single_branch: SingleBranchReducer,
  // admin vars
  admins: get_all_adminsReducer,
  single_admin: single_adminReducer,
  // groups vars
  groups: groupsReducer,
  group: groupReducer,
  group_customer_page: GroupCustomersPaginationReducer,
  // Coupon vars
  coupons: couponsReducer,
  coupon: couponReducer,
  coupons_page: CouponsPaginationReducer,
  allpromos: promosReducer,
  promo: singlePromoReducer,
  gift_card: singleGiftReducer,
  gift_cards_list: giftCardsReducer,
  coupon_groups: couponGroupReducer,
  get_single_product: getSingleProductReducer,
  get_all_coupons: allCouponsReducer,
  get_single_coupon: getSingleCouponReducer,
  gift_card_pagination: GiftCardPaginationReducer,
  promo_code_pagination: promoCodePaginationReducer,
  // orders vars
  order: orderReducer,
  orders: ordersReducer,
  all_orders_page: AllOrdersPaginationReducer,
  filtered_customers: FilteredCustomersReducer,
  search_customer: SearchCustomerReducer,
  // Dashboard vars
  dashboard_card: dashboard_card,
  dashboard_filters: dashboard_filters,
  dashboard_revenue: dashboard_revenue,
  dashboard_sales_pie: dashboard_sales_pie,
  dashboard_order_analytics: dashboard_ml_ordered_products,
  //  Customer vars
  customer_roles: CustomerRolesReducer,
  single_customer: singleCustomerReducer,
  customers: fetchCustomersReducer,
  customer_dashboard: CustomerDashboardReducer,
  customer_analytics_pie: CustomerAnalyticsPieReducer,
  customer_analytics_graph: CustomerAnalyticsGraphReducer,
  customer_order_history: CustomerOrderHistoryReducer,
  order_analytics_graph: OrderAnalyticsGraphReducer,
  customer_avg_purchase: CustomerAvgPurchaseReducer,
  customer_page: CustomerPaginationReducer,
  mostOrderPage: MostOrderPaginationReducer,
  customersList: FilteredCustomersListReducer,
  customer_wallet_balance_summary: CustomerWalletBalanceReducer,

  //  employees
  employees: fetchEmployeesReducer,
  employee: fetchSingleEmployeeReducer,
  empPage: EmployeePaginationReducer,
  empPageRecords: EmployeePaginationRecordsReducer,
  career_page: CareerPaginationReducer,
  career: fetchCareerReducer,
  employee_sales_analytics: EmployeeSalesAnalyticsReducer,
  employee_orders_taken: EmployeeOrdersTakenReducer,
  emp_orders_page: OrdersPaginationReducer,
  employee_logout_Report: logoutEmployeeReportReducer,
  logout_report_data: getSingleEmployeeReport,
  branches: getAllBranchesReducer,
  single_employee_logout_report: fetchSingleEmployeeLogoutReport,
  single_custom_logout_report: fetchCustomLogoutReport,
  // reports
  // -------> Staff
  sales_by_staff: fetchSalesByStaff,
  sbs_page: SalesByStaffPagination,
  sales_by_staff_daily: fetchSalesByStaffDaily,
  sbsd_page: SalesByStaffDailyPagination,
  staff_order_list: fetchStaffOrderList,
  sol_page: StaffOrderListPagination,
  // -------> Sales
  sales_by_branch: fetchSalesByBranch,
  sales_by_branch_daily: fetchSalesByBranchDaily,
  // -------> Orders
  oll_page: OrderListLocationPagination,
  order_list_by_location: fetchOrderListLocation,
  itemwise_sales_summary: fetchItemWiseSalesSummary,
  // -------> Location
  sbl_page: SalesByLocationPagination,
  sales_by_location: fetchSalesByLocation,
  sbld_pagination: SalesByLocationDailyPagination,
  sales_by_location_daily: fetchSalesByLocationDaily,
  // -------> Payment
  sales_by_payment_method: SalesByPaymentMethodReducer,
  sbpm_page: SBPMPagination,
  sales_by_payment_method_daily: SalesByPaymentMethodDailyReducer,
  sbpmd_page: SBPMDPagination,

  // ---------> Wallet Report
  wallet_credit_history: walletCreditHistoryReducer,

  // -------> Customer
  customer_by_group_report: CustomerByGroupReportReducer,
  customer_sales_summary_report: CustomerSalesSummaryReducer,
  customer_sales_order_list: CustomerSalesOrderListReducer,
  cbg_page: CBGPagination,
  customer_list_by_group_report: CustomerListByGroupReportReducer,
  customer_dashboard: CustomerDashboardReducer,
  customer_analytics_graph: CustomerAnalyticsGraphReducer,
  customer_ananlytics_pie: CustomerAnalyticsPieReducer,
  customer_most_order: CustomerMostOrderGraphReducer,
  // customergroupchangelogs
  customer_group_changelogs: CustomerGroupChangeLogsReducer,

  clbg_page: CLBGPagination,
  per_customer_analysis_report: PerCustomerAnalysisReport,
  pcol_page: PCOLPagination,
  per_customer_orderlist: PerCustomerOrderList,
  // CATALOG
  getBranchInCat: get_category_branchesReducer,
  getCatByBranch: get_product_branchReducer,
  singleCategory: UpdateReducer,
  AddProductToCat: AddProductToCat,
  getCurrentCat: GET_CURRENT_CAT,
  getCurrentBranch: GET_CURRENT_BRANCH,
  delete_Product: DELETE__PRODUCT,
  update_Product: UPDATE__PRODUCT,
  items_Available: ITEMS__AVAILABLE,
  singleAddon: GET_SINGLE__ADDON,
  updateAddonProduct: UPDATE_ADDON__PRODUCT,
  deleteSingleAddon: DELETE_SINGLE__ADDON,
  deleteCategory: DELETE__CATEGORY,
  deleteProduct: DELETE_PRODUCT,
  deleteAddonOption: DELETE_ADDON_OPTION,
  addon_products: getAddonProducts,
  deleteAddonProduct: DELETE_ADDON_PRODUCTS,
  AddProductToAddon: AddProductToAddon,
  get_product_addons: getProductAddons,
  qrcodes: QRCodesReducer,
  single_qrcode: SingleQRCodeReducer,
  // export csv

  // investor reducer
  allInvestors: AllInvestorReducers,
  deleteInvestor: deleteInvestorReducer,
  delBranchInvestor: deleteBranchInvestorReducer,
  addBranchInvestor: addBranchInvestor,
  dailyInvestorReport: fetchDailyInvestorReport,
  customInvestorReport: fetchCustomInvestorReport,
  allCompany: AllCompanyReducer,
  // sbsCSV: exportsbsCSV,

  // inventory reducers
  inventory_store_list: inventoryStoreReducer,
  inventory_employee_list: inventoryEmployeesReducer,
  store_list_pagination: StoreListPaginationReducer,
  get_single_store_data: singleStoreReducer,
  employee_list_pagination: EmployeeListPaginationReducer,
  get_single_inventory_employee: singleEmployeeReducer,
  get_all_stores: inventoryAllStoreReducer,
  get_all_vendors: inventoryAllVendorReducer,
  vendor_list_pagination: VendorListPaginationReducer,
  get_single_vendor: singleVendorReducer,
  get_count_purchased_order: inventoryCountPurchaseOrderReducer,
  get_all_purchase_order: inventoryAllPurchaseOrderReducer,
  purchase_order_list_pagination: PurchaseOrderListPaginationReducer,
  get_all_items_list: inventoryAllItemsListReducer,
  all_items_list_pagination: ItemsListPaginationReducer,
  get_items_units_list: inventoryItemsUnitReducer,
  get_single_item: singleItemReducer,
  get_single_purchase_order: singlePurchaseOrderReducer,
  get_all_branches: branchesInventoryReducer,
  get_cafe_employees_list: inventoryCafeEmployeeList,
  cafe_employee_list_pagination: cafeEmployeeListPaginationReducer,
  get_single_cafe_employee: singleCafeEmployeeReducer,
  get_cafe_stock: cafeStockReducer,
  cafe_stock_pagination: cafeStockPaginationReducer,
  store_stock_pagination: storeStockPaginationReducer,
  get_store_stock: storeStockReducer,

  // notifications reducers
  get_all_notifications: allNotificationListReducer,
  get_all_notification_branches: getNotificationBranchesReducer,
  notification_list_pagination: notificationPaginationReducer,
  single_notification: getSingleNotificationReducer,

  // credit
  get_single_credit: singleCreditReducer,
  get_available_credit: availableCreditReducer,
  get_used_credit: usedCreditReducer,
  credit_available_pagination: creditAvailableListPagination,
  credit_used_pagination: creditUsedListPagination,

  // cafe request
  cafe_request_count: inventoryCountCafeRequest,
  cafe_request_list: allCafeRequestListReducer,
  cafe_request_pagination: cafeRequestPaginationReducer,
  get_single_cafe_request: singleCafeRequestReducer,
  sold_product_report: soldProductReportReducer,
  
  // count store request receiver
  count_store_req_receiver: inventoryCountStoreReqReceiverReducer,
  get_all_store_req_receiver: inventoryAllStoreReqReceiver,
  store_req_receiver_pagination: storeReceiveReqListPaginationReducer,
  count_store_req_sender: inventoryCountStoreReqSenderReducer,
  get_all_store_req_sender: inventoryAllStoreReqSenderListReducer,
  store_req_sender_list_pagination: storeSenderReqListPaginationReducer,
  get_single_sender_req: singleStoreSenderReqReducer,

  // zomato
  zomato_outlets: getAllOutletsReducer,
});

export default rootReducer;

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import { fetchEmployees } from "../../../store/actionCreators/Employees/EmployeeAction";
import Async from "react-select/async";
import axios from "axios";
// import Loader from "../../Loader/Loader";
import Loader from "../../Loader/LoaderTable";
import ScrollButton from "../../scrolltotop/ScrollButton";
import { toast } from "react-toastify";
import { getSalesByBranchReport } from "./../../../store/actionCreators/Reports/SalesReportAction";

const SalesByBranchReport = (props) => {
  const dispatch = useDispatch();
  const sbsReport = useSelector((state) => state.sales_by_branch);

  const branch = useSelector((state) => state.branch);
  const employees = useSelector((state) => state.employees);
  const [page, setPage] = useState(1);
  const [showReportClicked, setShowReportClicked] = useState(false);

  const [isDataFetched, setisDataFetched] = useState(false);
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });
  const [empArr, setEmpArr] = useState({
    label: "All",
    value: "All",
  });
  const [query, setQuery] = useState({
    branch_id: "All",
    status: "All",
    // start: "2021-01-19",
    // end: "2022-01-02",

    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });

  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  const [isDaily, setIsDaily] = useState(false);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    setProductData([]);
    dispatch(fetchEmployees(1, "All", "All", ""));
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [branch, employees]);

  useEffect(() => {
    if (sbsReport?.data?.status !== "success") {
      setisDataFetched(false);
    }
    if (
      sbsReport.data &&
      sbsReport?.data?.status === "success" &&
      showReportClicked
    ) {
      setisDataFetched(true);
      setIsDaily(false);
      const productDataClone = sbsReport?.data?.data.map((e) => {
        return {
          ...e,
          branch_name: e.branches?.branch_name,
          membership_discount:
            Number.parseFloat(
              Math.round(e.membership_discount * 100) / 100
            ).toLocaleString() || 0,
          total_tax:
            Number.parseFloat(
              Math.round(e.total_tax * 100) / 100
            ).toLocaleString() || 0,
          wallet_amount:
            Number.parseFloat(
              Math.round(e.wallet_amount * 100) / 100
            ).toLocaleString() || 0,
          delivery_sales:
            Number.parseFloat(
              Math.round(e.delivery_sales * 100) / 100
            ).toLocaleString() || 0,
          cash_sales:
            Number.parseFloat(
              Math.round(e.cash_sales * 100) / 100
            ).toLocaleString() || 0,
          card_sales:
            Number.parseFloat(
              Math.round(e.card_sales * 100) / 100
            ).toLocaleString() || 0,
          total_discount:
            Number.parseFloat(
              Math.round(e.total_discount * 100) / 100
            ).toLocaleString() || 0,
          total_orders:
            Number.parseFloat(
              Math.round(e.total_orders * 100) / 100
            ).toLocaleString() || 0,
          delivery_charges:
            Number.parseFloat(
              Math.round(e.delivery_charges * 100) / 100
            ).toLocaleString() || 0,
          paid_price:
            Number.parseFloat(
              Math.round(e.paid_price * 100) / 100
            ).toLocaleString() || 0,
          coupon_discount: e.coupon_discount?.toFixed(2)?.toLocaleString() ?? 0,
          Average_Ticket_Size:
            Number.parseFloat(
              Math.round(e.Average_Ticket_Size * 100) / 100
            ).toLocaleString() || 0,
        };
      });
      //   productDataClone.push(total);
      console.log(productDataClone, "check total");
      setProductData(productDataClone);
      setPage(1);
    } else if (sbsReport?.data?.status === "failure") {
      setisDataFetched(true);
      setProductData([]);
    }
  }, [sbsReport, sbsReport?.data]);

  const queryClone = { ...query };
  queryClone.branch_id = branchSelected.value;

  const exportSBSCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_branch_sales`,
        queryClone
      );
      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      } else {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data?.data;
    } catch (error) {}
  };

  const setDefaultData = () => {
    if (branch.data && branch.data?.data) {
      const branchArrClone = branch.data?.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }

    if (employees.data && employees.data?.data) {
      const empArrClone = employees.data?.data.map((b) => ({
        label: b.full_name,
        value: b.employee_id,
      }));
      empArrClone.unshift({ label: "All", value: "All" });
      setEmpArr(empArrClone);
    }
  };

  const onShowReport = () => {
    setProductData([]);
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    // if (durationSelected.value == "All")
    dispatch(getSalesByBranchReport(queryClone));
    // else dispatch(getSalesByStaffDaily(queryClone));
    setShowReportClicked(true);
    // exportSBSCSV();
  };

  const columnsDaily = [
    {
      dataField: "date",
      text: "Day",
      sort: true,
    },
    {
      dataField: "full_name",
      text: "Staff",
      sort: true,
    },
    {
      dataField: "order_count",
      text: "Order Count",
      sort: true,
    },
    {
      dataField: "paid_price",
      text: "Sales Total",
      sort: true,
    },
  ];

  const columns = [
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },

    {
      dataField: "total_orders",
      text: "Total Orders",
      sort: true,
    },
    {
      dataField: "Average_Ticket_Size",
      text: "Average Ticket Size",
      sort: true,
    },
    {
      dataField: "paid_price",
      text: "Total Sales",
      sort: true,
    },

    {
      dataField: "cash_sales",
      text: "Cash Sales",
      sort: true,
    },
    {
      dataField: "card_sales",
      text: "Card Sales",
      sort: true,
    },
    {
      dataField: "wallet_amount",
      text: "Wallet Sales",
      sort: true,
    },
    {
      dataField: "delivery_sales",
      text: "Delivery Partner Sales",
      sort: true,
    },
    {
      dataField: "membership_discount",
      text: "Membership Discount",
      sort: true,
    },

    {
      dataField: "coupon_discount",
      text: "Coupon Discount",
      sort: true,
    },
    {
      dataField: "total_discount",
      text: "Total Discount",
      sort: true,
    },
    {
      dataField: "total_tax",
      text: "Total Tax",
      sort: true,
    },
    {
      dataField: "delivery_charges",
      text: "Delivery Partner charges",
      sort: true,
    },
  ];

  const total = {
    // not_include: true,
    branch_name: <strong>Total</strong>,
    Cash_Sales: (
      <strong>
        {sbsReport.data
          ? Number.parseFloat(sbsReport.data.Cash_Sales_s).toLocaleString()
          : ""}
      </strong>
    ),
    Order_Count: (
      <strong>
        {" "}
        {sbsReport.data
          ? Math.round(sbsReport.data.Order_Count_s * 100) / 100
          : ""}
      </strong>
    ),
    Discount: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.Discount_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    card_sales: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.card_sales_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    Total_Tax: (
      <strong>
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.Total_Tax_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    delivery_sales: (
      <strong>
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.total_delivery_sales_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    delivery_charges: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.delivery_charges_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    wallet_sales: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.wallet_sales_s * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
    Sales: (
      <strong>
        {" "}
        {sbsReport.data
          ? Number.parseFloat(
              Math.round(sbsReport.data.total_sales * 100) / 100
            ).toLocaleString()
          : ""}
      </strong>
    ),
  };

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: productData.length,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const orderStatusOptions = [
    { label: "All", value: "All" },
    { label: "Preparing", value: "Preparing" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">SALES BY BRANCH</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Order status:</td>
                      <td className="">
                        <Select
                          options={orderStatusOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "csv", value: "csv" }]}
                value={{ label: "csv", value: "csv" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportSBSCSV();
                }}
              >
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="branch_id"
                    columns={isDaily ? columnsDaily : columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="branch_id"
                        columns={isDaily ? columnsDaily : columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="branch_id"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {!productData.length > 0 &&
                                  sbsReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {sbsReport?.data?.status === "failure" &&
                                    !sbsReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
    // </Container>
  );
};

export default SalesByBranchReport;

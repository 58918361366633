import React, { useMemo } from "react";
import { getAddonSizeData } from "./utils";

const SoldProductReportAddonTable = ({ addOnData = {} }) => {
  const { options_add_ons_report = [] } = addOnData;

  const addonSizesData = useMemo(
    () => getAddonSizeData(options_add_ons_report),
    [options_add_ons_report]
  );

  return (
    <table className="table">
      <thead>
        {" "}
        <th className="name" style={{ textAlign: "left", paddingLeft: "10px" }}>
          {addOnData?.add_on}
        </th>
        <th className="text-center">-</th>
        <th className="text-center">Small</th>
        <th className="text-center">Medium</th>
        <th className="text-center">Large</th>{" "}
      </thead>
      <tbody>
        {addonSizesData.map((item, i) => {
          return (
            <tr key={i}>
              <td className="name">{item.name}</td>
              <td className="text-center">{item["-"] || "-"}</td>
              <td className="text-center">{item?.Small || "-"}</td>
              <td className="text-center">{item?.Medium || "-"}</td>
              <td className="text-center">{item?.Large || "-"}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SoldProductReportAddonTable;

export const fetchEmployeesReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_EMPLOYEES":
      return action.employees;
    case "SET_EMPLOYEES":
      return "";
    default:
      return state;
  }
};
export const fetchCareerReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_CAREER":
      return action.res;
    case "SET_CAREER":
      return action.res;
    default:
      return state;
  }
};
export const fetchSingleEmployeeReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_EMPLOYEE":
      return action.employee;
    case "SET_SINGLE_EMPLOYEE":
      return "";
    default:
      return state;
  }
};
export const EmployeeSalesAnalyticsReducer = (state = "", action) => {
  switch (action.type) {
    case "EMPLOYEE_SALES_ANALYTICS":
      return action.employee_sales_analytics;
    case "SET_EMPLOYEE_SALES_ANALYTICS":
      return "";
    default:
      return state;
  }
};
export const EmployeePaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_PAGE":
      return state;
    case "SET_EMPLOYEE_PAGE":
      return action.page;
    default:
      return state;
  }
};
export const EmployeePaginationRecordsReducer = (state = 10, action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_RECORDS":
      return state;
    case "SET_EMPLOYEE_RECORDS":
      return action.records;
    default:
      return state;
  }
};
export const CareerPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "GET_CAREER_PAGE":
      return state;
    case "SET_CAREER_PAGE":
      return action.page;
    default:
      return state;
  }
};
export const OrdersPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "GET_ORDER_PAGE":
      return state;
    case "SET_ORDER_PAGE":
      return action.page;
    default:
      return state;
  }
};
export const EmployeeOrdersTakenReducer = (state = "", action) => {
  switch (action.type) {
    case "EMPLOYEE_ORDERS_TAKEN":
      return action.employee_orders_taken;
    case "SET_EMPLOYEE_ORDERS_TAKEN":
      return "";
    default:
      return state;
  }
};

export const RolesReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ROLES":
      return action.roles;
    case "SET_ROLES":
      return "";
    default:
      return state;
  }
};

export const logoutEmployeeReportReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_LOGOUT_EMPLOYEE_REPORT":
      return action.employee_logout_Report;

    default:
      return state;
  }
};
export const getSingleEmployeeReport = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_EMPLOYEE_LOGOUT_REPORT":
      return action.logout_report_data;

    default:
      return state;
  }
};
export const getAllBranchesReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_BRANCHES":
      return action.branches;

    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import { useDispatch } from "react-redux";
import { AddMoreAddonOptions } from "../../../store/actionCreators/Catalog/Catalog";

const AddMoreAddons = ({ show, close, data, setFormData }) => {
  console.log(data.add_ons_id);
  // add new addon field
  const [serviceList, setServiceList] = useState([
    { title: "", price: 0, order: 0, sku: "" },
  ]);

  console.log(serviceList[0].title.length, "sku");

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    console.log(serviceList);
    setServiceList((serviceList) => [
      ...serviceList,
      { title: "", price: 0, order: 0, sku: "" },
    ]);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    if (name === "title") {
      list[index][name] = value;
    } else if (name === "sku") {
      list[index][name] = value;
    } else {
      list[index][name] = parseInt(value);
    }
    setServiceList(list);
  };

  const addon = data.add_ons_id;

  const handleSubmit = (e) => {
    AddMoreAddonOptions(addon, serviceList, setFormData);
    console.log(addon, serviceList, "checking");
    e.preventDefault();

    close();
  };

  const SubmitDisabled =
    serviceList[0]?.title?.length === 0 || serviceList[0]?.sku?.length === 0;
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add more Addon Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="d-flex justify-content-around mr-2">
              <button
                type="button"
                onClick={handleServiceAdd}
                className="btn btn-info "
              >
                <span>Add more Addon Options</span>
              </button>
            </div>

            <Row>
              {serviceList.map((singleService, index) => (
                <div key={index} className="services">
                  <Row>
                    <Col xl={3} md={6} sm={12}>
                      <div className="mb-3 p-2">
                        <label className="form-label">Title</label>
                        <input
                          name="title"
                          type="text"
                          id="service"
                          className="form-control"
                          value={singleService.service}
                          onChange={(e) => handleServiceChange(e, index)}
                          required
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={6} sm={12}>
                      <div className="mb-3 p-2">
                        <label className="form-label">Price</label>
                        <input
                          name="price"
                          type="number"
                          className="form-control"
                          id="service"
                          value={singleService.service}
                          onChange={(e) => handleServiceChange(e, index)}
                          required
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={6} sm={12}>
                      <div className="mb-3 p-2">
                        <label className="form-label">Order</label>
                        <input
                          name="order"
                          type="number"
                          className="form-control"
                          id="service"
                          value={singleService.service}
                          onChange={(e) => handleServiceChange(e, index)}
                          required
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={6} sm={12}>
                      <div className="mb-3 p-2">
                        <label className="form-label">SKU</label>
                        <div className="d-flex ">
                          <input
                            name="sku"
                            type="text"
                            className="form-control"
                            id="service"
                            value={singleService.service}
                            onChange={(e) => handleServiceChange(e, index)}
                            required
                            style={{ display: "inline" }}
                          />

                          {serviceList.length !== 1 && (
                            <button
                              type="button"
                              onClick={() => handleServiceRemove(index)}
                              className="btn btn-danger m-0 p-0 ms-3"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                              }}
                            >
                              <span>-</span>
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="second-division"></div>
                    </Col>
                  </Row>

                  {/* {serviceList.length - 1 === index && (
                <button
                  type="button"
                  onClick={handleServiceAdd}
                  className="btn btn-info ms-2"
                >
                  <span>Add new options</span>
                </button>
              )} */}
                </div>
              ))}
            </Row>
            <div className="p-2">
              <button
                className="btn btn-primary "
                onClick={handleSubmit}
                disabled={SubmitDisabled}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddMoreAddons;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSinglePurchaseOrder } from "../../../store/actionCreators/Inventory/InventoryAction";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { BiArrowBack } from "react-icons/bi";

const EditPurchaseOrder = ({ sideToggle }) => {
  const purchaseOrder = useSelector((state) => state.get_single_purchase_order);
  const allStatus = useSelector((state) => state.get_count_purchased_order);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState({
    status: "",
    file: null,
    msg: "",
  });

  console.log(data.bill, "file");

  const [file, setFile] = useState(null);

  console.log(file, "file");
  const [orderItemData, setOrderItemData] = useState([]);

  useEffect(() => {
    dispatch(getSinglePurchaseOrder(params.purchase_orders_id));
  }, []);

  useEffect(() => {
    setData(purchaseOrder);
    setOrderItemData(purchaseOrder?.purchase_order_items || []);
  }, [purchaseOrder]);

  const updatePurchaseOrder = () => {
    // const file = data?.file ? data.file : null;
    // console.log(file, "file");
    const formdata = new FormData();
    formdata.set("order_status", data.status);
    formdata.set("purchase_orders_id", data.purchase_orders_id);
    formdata.set("file", file);
    formdata.set("store_id", data.store_id);
    formdata.set("msg", data.msg);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_purchase_order`,
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.status === "success") {
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(getSinglePurchaseOrder(params.purchase_orders_id));
        } else {
          toast.error(`${response.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  const onUpdatePurchaseOrderItem = (item) => {
    // const file = data?.file ? data.file : null;
    const formdata = new FormData();
    formdata.set("purchase_orders_id", item.purchase_orders_id);
    formdata.set("order_item_status", item.status);
    formdata.set("purchase_order_items_id", item.purchase_order_items_id);
    formdata.set("file", file);
    formdata.set("msg", item.msg);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_purchase_order`,
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.status === "success") {
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(getSinglePurchaseOrder(params.purchase_orders_id));
        } else {
          toast.error(`${response.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
    setData({ ...data, file: file });
  };

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <button
          className="btn btn-primary mb-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          {" "}
          <BiArrowBack className="mr-2" />
          Back
        </button>
        {purchaseOrder ? (
          <Row>
            <Col className="col-12 mb-5">
              <Card>
                <Card.Body style={{ padding: "40px" }}>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      Edit Purchase Order Details
                    </Card.Title>
                  </div>

                  <Row className="mb-2">
                    <Card
                      style={{
                        background: "transparent",
                        width: "100%",
                        color: "#8d8d8d",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Card.Body>
                        <Row>
                          <Col>
                            {" "}
                            <strong>Vendor Name:</strong>
                            <span className="text-capitalize">
                              {data.vendor_name}
                            </span>
                          </Col>
                          <Col>
                            {" "}
                            <strong>Total Price :</strong>
                            &#8377;{data.total_price}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {" "}
                            <strong> Subtotal:</strong> &#8377;
                            {data.sub_total}
                          </Col>

                          <Col>
                            <strong>Discount:</strong>
                            &#8377;{data.discount}
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            {" "}
                            <strong>Tax: </strong>
                            &#8377;{data.tax}
                          </Col>

                          <Col>
                            {" "}
                            <strong>Status:</strong>
                            <div className="d-flex ">
                              <Select
                                value={{
                                  label: String(data.status),
                                  value: data.status,
                                }}
                                options={allStatus.data.arr.map((i) => {
                                  return {
                                    label: i.status,
                                    value: i.status,
                                  };
                                })}
                                onChange={(e) =>
                                  setData({ ...data, status: e.value })
                                }
                              />
                              <button
                                onClick={updatePurchaseOrder}
                                className="btn btn-warning mx-2"
                              >
                                Update
                              </button>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <div className="inputWrapper">
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="inputGroupFile01"
                                  aria-describedby="inputGroupFileAddon01"
                                  onChange={handleFileChange}
                                  accept="application/pdf"
                                  multiple={false}
                                />
                                <label
                                  className="custom-file-label font-weight-bold  border border-2 p-1 border-dark pe-auto"
                                  for="inputGroupFile01"
                                  style={{ cursor: "pointer" }}
                                >
                                  Choose file
                                </label>
                              </div>
                            </div>
                            <div>
                              <strong>Bill:</strong>{" "}
                              {!!file
                                ? `${file?.name} - ${file?.type}`
                                : `${data?.bill ? String(data.bill) : ""}`}
                            </div>
                            {!!data.bill && (
                              <button
                                className="btn-sm btn btn-primary"
                                onClick={() => window.open(data.bill)}
                              >
                                View
                              </button>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-start gap-2 mt-3">
                            {" "}
                            <strong>Note: </strong>
                            <input
                              type="text"
                              className="form-control "
                              style={{ width: "400px" }}
                              name="msg"
                              value={data.msg}
                              onChange={(e) =>
                                setData({ ...data, msg: e.target.value })
                              }
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>

                  <b className="mt-5">Purchase Order Items:</b>

                  {orderItemData.map((item, i) => {
                    return (
                      <Card.Body key={i}>
                        <b className="fw-bold">
                          Order Id: {item.purchase_orders_id}
                        </b>
                        <hr className="my-1" />
                        <div className="d-flex flex-column">
                          <table>
                            <tr></tr>
                            <th>Name:</th>
                            <th>Unit:</th>
                            <th>Tax:</th>
                            <th>Total Price :</th>
                            <th>Vendor Name:</th>
                            <th>Note:</th>
                            <th>Status:</th>
                            <th>Action:</th>
                            <tr>
                              <td>{item.name}</td>

                              <td>{item.unit}</td>

                              <td>{item.tax}</td>

                              <td>{item.total_price}</td>

                              <td>{item.vendor_name}</td>
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  // className="form-control "
                                  // style={{ width: "400px" }}
                                  name="msg"
                                  value={item.msg}
                                  onChange={(e) =>
                                    setOrderItemData((list) => {
                                      const updatedList = [...list];
                                      updatedList[i] = {
                                        ...updatedList[i],
                                        msg: e.target.value,
                                      };

                                      return updatedList;
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <Select
                                  value={{
                                    label: String(item.status),
                                    value: item.status,
                                  }}
                                  options={allStatus.data.arr.map((i) => {
                                    return {
                                      label: i.status,
                                      value: i.status,
                                    };
                                  })}
                                  onChange={(e) =>
                                    setOrderItemData((list) => {
                                      const updatedList = [...list];
                                      updatedList[i] = {
                                        ...updatedList[i],
                                        status: e.value,
                                      };
                                      //   updatedList[i].status = e.value;
                                      return updatedList;
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <Button
                                  type="submit"
                                  className="btn btn-warning"
                                  onClick={() =>
                                    onUpdatePurchaseOrderItem(item)
                                  }
                                >
                                  Update
                                </Button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </Card.Body>
                    );
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default EditPurchaseOrder;

export const inventoryStoreReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_STORE":
      return action.store;
    default:
      return state;
  }
};
export const inventoryAllPurchaseOrderReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_PURCHASE_ORDER":
      return action.allPurchaseOrder;
    default:
      return state;
  }
};
export const inventoryAllItemsListReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_ITEMS_LIST":
      return action.items;
    default:
      return state;
  }
};
export const inventoryCountPurchaseOrderReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_COUNT_PURCHASED_ORDER":
      return action.countPurchasedOrders;
    default:
      return state;
  }
};
export const inventoryAllStoreReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_STORE":
      return action.allStores;
    default:
      return state;
  }
};
export const inventoryAllVendorReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_VENDOR":
      return action.allVendors;
    default:
      return state;
  }
};
export const inventoryItemsUnitReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ITEMS_UNIT":
      return action.itemsUnit;
    default:
      return state;
  }
};
export const inventoryEmployeesReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_INVENTORY_EMPLOYEE":
      return action.inventoryEmployees;
    default:
      return state;
  }
};

export const singleStoreReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_STORE":
      return action.singleStore;
    case "UPDATE_SINGLE_STORE":
      return action.singleStore;
    default:
      return state;
  }
};
export const singlePurchaseOrderReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_PURCHASE_ORDER":
      return action.singlePurchaseOrder;
    case "UPDATE_SINGLE_PURCHASE_ORDER":
      return action.singlePurchaseOrder;
    default:
      return state;
  }
};
export const singleItemReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_ITEM":
      return action.singleItem;
    case "UPDATE_SINGLE_ITEM":
      return action.singleItem;
    default:
      return state;
  }
};
export const singleEmployeeReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_INVENTORY_EMPLOYEE":
      return action.singleEmployee;
    case "UPDATE_SINGLE_INVENTORY_EMPLOYEE":
      return action.singleEmployee;
    default:
      return state;
  }
};
export const singleVendorReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_VENDOR":
      return action.singleVendor;
    case "UPDATE_SINGLE_VENDOR":
      return action.singleVendor;
    default:
      return state;
  }
};

export const StoreListPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_STORE_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
export const ItemsListPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_ITEMS_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
export const EmployeeListPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_EMPLOYEE_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
export const VendorListPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_VENDOR_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
export const PurchaseOrderListPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_PURCHASE_ORDER_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
export const branchesInventoryReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_BRANCHES_INVENTORY":
      return action.inventoryAllBranches;
    default:
      return state;
  }
};
export const inventoryCafeEmployeeList = (state = "", action) => {
  switch (action.type) {
    case "GET_CAFE_EMPLOYEE":
      return action.cafeEmployees;
    default:
      return state;
  }
};
export const cafeEmployeeListPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_CAFE_EMPLOYEE_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
export const singleCafeEmployeeReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_CAFE_EMPLOYEE":
      return action.singleCafeEmployee;
    case "UPDATE_SINGLE_CAFE_EMPLOYEE":
      return action.singleCafeEmployee;
    default:
      return state;
  }
};

export const cafeStockReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_CAFE_STOCK":
      return action.cafeStock;
    default:
      return state;
  }
};
export const storeStockReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_STORE_STOCK":
      return action.storeStock;
    default:
      return state;
  }
};

export const cafeStockPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_CAFE_STOCK_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
export const storeStockPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_STORE_STOCK_PAGINATION":
      return action.page;
    default:
      return state;
  }
};

export const inventoryCountCafeRequest = (state = "", action) => {
  switch (action.type) {
    case "GET_COUNT_CAFE_REQUESTS":
      return action.countCafeRequests;
    default:
      return state;
  }
};
export const allCafeRequestListReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_CAFE_REQUESTS":
      return action.allCafeReq;
    default:
      return state;
  }
};

export const cafeRequestPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_CAFE_REQUEST_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};

export const singleCafeRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_SINGLE_CAFE_REQUEST":
      return action.singleCafeReq;
    default:
      return state;
  }
};

export const soldProductReportReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SOLD_PRODUCT_REPORT":
      return action.soldProductReport;
    default:
      return state;
  }
};

export const inventoryCountStoreReqReceiverReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_COUNT_STORE_REQUEST_RECEIVER":
      return action.countStoreReqReceiver;
    default:
      return state;
  }
};

export const inventoryAllStoreReqReceiver = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_STORE_REQ_RECEIVER":
      return action.storeReqReceiver;
    default:
      return state;
  }
};

export const storeReceiveReqListPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_STORE_RECEIVE_REQUEST_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};

export const inventoryCountStoreReqSenderReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_COUNT_STORE_REQUEST_SENDER":
      return action.countStoreReqSender;
    default:
      return state;
  }
};

export const inventoryAllStoreReqSenderListReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_ALL_STORE_REQ_SENDER":
      return action.storeReqSender;
    default:
      return state;
  }
};

export const storeSenderReqListPaginationReducer = (state = 1, action) => {
  switch (action.type) {
    case "SET_STORE_SENDER_REQUEST_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};

export const singleStoreSenderReqReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_STORE_REQ_SENDER":
      return action.singleSenderReq;
    case "GET_SINGLE_STORE_REQ_SENDER":
      return action.singleSenderReq;
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import {
  getAllStoreList,
  getVendorList,
} from "../../../store/actionCreators/Inventory/InventoryAction";
import axios from "axios";
import { toast } from "react-toastify";
import VendorListPagination from "./VendorListPagination";
import Unauthorized from "../../unauthorized";

const AllVendors = (props) => {
  const dispatch = useDispatch();
  const allVendorList = useSelector((state) => state.get_all_vendors);
  const page = useSelector((state) => state.vendor_list_pagination);
  const allStoreList = useSelector((state) => state.get_all_stores);
  const [loading, setLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [currStores, setCurrStores] = useState("All");
  const [currStoreName, setCurrStoreName] = useState("");
  const [currVendorStatus, setCurrVendorStatus] = useState("All");
  const [vendorStatus, setVendorStatus] = useState("");

  useEffect(() => {
    setLoading(true);
    dispatch(getVendorList(currStores, page, currVendorStatus));
    dispatch(getAllStoreList());
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getVendorList(currStores, page, currVendorStatus));
  }, [currStores, page, currVendorStatus]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [allVendorList]);

  const setDefaultData = () => {
    let vendorList = [];
    if (allVendorList.data && allVendorList.data.data) {
      vendorList = allVendorList.data.data.map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleString().split("T")[0],
      }));
    }
    setVendorList(vendorList);
  };

  const deleteFunction = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/delete_vendors?vendor_lists_id=${id}`
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          toast.success(`deleted successFully `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(getVendorList(1));
        } else {
          toast.error(`${response.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewvendor/${row.vendor_lists_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`editvendor/${row.vendor_lists_id}`}
            onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>

          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => deleteFunction(row.vendor_lists_id)}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewvendor/${row.vendor_lists_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Contact",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: allVendorList.data?.per_page,
    totalSize: allVendorList.data
      ? allVendorList.data.total_items
        ? allVendorList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const allVendorList = [...vendorList];
    return allVendorList;
  };

  const displayStores = () => {
    const additional = [{ store_name: "All", store_id: "All" }];
    return (additional.concat(allStoreList?.data?.data) || []).map(
      (item, index) => {
        return (
          <Dropdown.Item
            key={item?.store_id}
            eventKey={`${item?.store_id},${item?.store_name}`}
          >
            {" "}
            {item?.store_name}
          </Dropdown.Item>
        );
      }
    );
  };

  const displayStatus = () => {
    return [
      { label: "All", value: "All" },
      { label: "Activated", value: "true" },
      { label: "Deactivated", value: "false" },
    ].map((item, index) => {
      return (
        <Dropdown.Item key={index} eventKey={`${item?.label},${item?.value}`}>
          {" "}
          {item?.label}
        </Dropdown.Item>
      );
    });
  };

  const handleSelect = (e) => {
    const value = e.split(",")[0];
    const name = e.split(",")[1];
    setCurrStores(value);
    setCurrStoreName(name);
  };

  const handleSelectStatus = (e) => {
    const value = e.split(",")[1];
    const name = e.split(",")[0];
    setVendorStatus(name);
    setCurrVendorStatus(value);
  };

  
  if (props.viewPermission) {
  if (!loading) {
    return (
      <React.Fragment>
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
            Inventory Vendors{" "}
          </Col>
          <Col lg={4} sm={8} xs={12}></Col>
          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between mb-2">
                      <div
                        className="d-flex justify-content-between "
                        style={{ gap: "12px" }}
                      >
                        <Card.Title>All Vendors</Card.Title>

                        <DropdownButton
                          variant="light"
                          title={currStoreName ? currStoreName : "All Stores"}
                          id="dropdown-menu-align-right"
                          onSelect={handleSelect}
                        >
                          {displayStores()}
                        </DropdownButton>

                        <DropdownButton
                          variant="light"
                          title={vendorStatus ? vendorStatus : "Vendor Status"}
                          id="dropdown-menu-align-right"
                          onSelect={handleSelectStatus}
                        >
                          {displayStatus()}
                        </DropdownButton>
                      </div>

                      <Link
                        className="btn btn-warning"
                        to={"/inventory/addvendor"}
                      >
                        Add New Vendor
                      </Link>
                    </div>

                    {
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="vendor_lists_id"
                        columns={columns}
                        data={getAllRows()}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="vendor_lists_id"
                            columns={columns}
                            data={getAllRows()}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="vendor_lists_id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <VendorListPagination
                                    pageNum={Math.ceil(
                                      allVendorList?.data
                                        ? allVendorList?.data?.total_items /
                                            allVendorList.data?.per_page
                                        : 1
                                    )}
                                  />
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  } else return <Loader />;
}  return <Unauthorized />;
};

export default AllVendors;
